
function NotFound404() {
  return (
    <>
      <h1>404 Error Page Not Found</h1>
    </>
  )
}

export default NotFound404
